import { Route, Routes, useNavigate } from 'react-router-dom';
import { Footer } from './default/footer';
import { HeaderComponent } from './default/header';
import { HomePage } from './pages/home.page';
import { RefObject, useRef } from 'react';
import { PrivacyPolicy } from './pages/privacy.policy';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { PrivacyPolicyLt } from './pages/privacy.policy.lt';
import {Helmet} from 'react-helmet';

export const MasterPage = () => {
    const { i18n } = useTranslation();

    const ServicesSectionRef = useRef<HTMLDivElement>(null);
    const ValuesSerctionRef = useRef<HTMLDivElement>(null);
    const AboutUsRef = useRef<HTMLDivElement>(null);
    const HomeRef = useRef<HTMLDivElement>(null);

    const scrollToSection = (sectionRef: RefObject<HTMLDivElement>) => {
        if (sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const { language } = i18n;
    const navigate = useNavigate();

    const OnSelectMenuItem = (item: string) => {
        navigate('/', { replace: true });

        switch (item) {
            case "Home":
                setTimeout(() => {
                    scrollToSection(HomeRef);
                }, 300);
                break;

            case "Services":
                setTimeout(() => {
                    scrollToSection(ServicesSectionRef);
                }, 300);
                break;

            case "OurValues":
                setTimeout(() => {
                    scrollToSection(ValuesSerctionRef);
                }, 300);
                break;

            case "AboutUs":
                setTimeout(() => {
                    scrollToSection(AboutUsRef);
                }, 300);
                break;

            default:
        }
    }

    return <div ref={HomeRef}>
        <Helmet>
          <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="01be3d69-45dc-44a0-9e5d-8ee69ecc499a" data-blockingmode="auto" type="text/javascript" />
       </Helmet>
        <HeaderComponent OnSelectMenuItem={OnSelectMenuItem} />
        <Routes>
            <Route path="/" element={
                <HomePage
                    OnSelectMenuItem={OnSelectMenuItem}
                    ServicesSectionRef={ServicesSectionRef}
                    ValuesSerctionRef={ValuesSerctionRef}
                    AboutUsRef={AboutUsRef} />}
            />
            <Route path="/privacy-policy" element={language === 'lt' ? <PrivacyPolicyLt /> : <PrivacyPolicy />} />
        </Routes>
        <Footer OnSelectMenuItem={OnSelectMenuItem} />
    </div>
}